import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const MsclkidCookie = () => {

    const [cookies, setCookie] = useCookies(["_uetmsclkid"])

    useEffect(() => {

        const queryString = typeof window !== "undefined" ? window.location.search : ""
        const urlParams = new URLSearchParams(queryString)
        const _uetmsclkid = urlParams ? urlParams.get("_uetmsclkid") : ""
        const currentDate = new Date()
        const nextMonth = new Date(currentDate)

        nextMonth.setMonth(currentDate.getMonth() + 1)

        if( _uetmsclkid ) {
            setCookie("_uetmsclkid", _uetmsclkid, { path: "/", expires: nextMonth })
        }

    }, [cookies, setCookie])
    
    return false

}

export default MsclkidCookie