import React from "react"
import Header from './Header'
import Footer from './Footer'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import StoryblokService from '../utils/storyblok-service'
import favicon from '/static/summit-training-favicon.png'
import { LiveChatWidget } from '@livechat/widget-react'
import { CookiesProvider } from "react-cookie"
import GclidCookie from 'src/utils/GclidCookie'
import MsclkidCookie from 'src/utils/MsclkidCookie'


export default function Layout({ children, location, isEditor, innerContent }){
  const { settings } = useStaticQuery(graphql`
  query Settings {
    settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
      edges {
        node {
          name
          full_slug
          content
        }
      }
    }
  }
  `)
  
  let content = JSON.parse(settings.edges[0].node.content)
  let parsedSetting = Object.assign({}, content, {content: content})

  const hasHero = children.props?.blok?.body[0]?.component === "hero" ? true : false;
  const heroColour = children.props?.blok?.body[0]?.component === "hero" ? children.props?.blok?.body[0]?.colour : '';
  const hasMargin = children.props?.blok?.body[0]?.component === "hero" || children.props?.blok?.body[0]?.component === "slimline-hero" ? true : false;

  return (
    <div className="bg-white">
      { isEditor ? 
        (<>
        <Helmet
            script={[
              {"src": `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.token}`,
              "type": "text/javascript"}
            ]}
        />
        <Helmet
            script={[
              {
              "innerHTML": `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
              "type": "text/javascript"
              }
            ]}
        />
        </>) : null
      }
      <Helmet>
        <link rel="icon" href={favicon} />
        <script src="https://kit.fontawesome.com/b1c70f5e3d.js" crossorigin="anonymous"></script>
        <link rel="stylesheet" href="https://use.typekit.net/xhv2jvs.css"></link>
      </Helmet>
      <Header settings={parsedSetting} hasHero={hasHero} heroColour={heroColour} hasMargin={hasMargin}/>
      <CookiesProvider>
        <GclidCookie/>
        <MsclkidCookie/>
        <main>
          {children}
        </main>
      </CookiesProvider>
      <Footer settings={parsedSetting} />
      <LiveChatWidget
        license="14222649"
        visibility="minimized"
      />
    </div>
  )
}